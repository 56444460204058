import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import {
  // useNavigate,
  useParams
} from 'react-router-dom';
import PageLoader from '../../../loaders/PageLoader';
import ApiTypes from '../../../networking/APItypes';
import APICall from '../../../networking/AxiousServices';
import { Card } from 'react-bootstrap';
import { Badges } from '../../../constants/Badges';
// import Routes from '../../../routes';
import Carousel from 'react-bootstrap/Carousel';
import { commonHelper } from '../../../common/commonHelper';


export default function CommercialAutoView() {
  document.title = `${process.env.REACT_APP_NAME} | Commercial Auto Quotation Details`;
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [insuranceDetails, setInsuranceDetails] = useState(null);

  // const navigate = useNavigate();

  const fetchDetails = async (insurance_id) => {
    let res = await APICall({
      url: ApiTypes.insuranceDetails,
      data: {
        customer_insurance_id: insurance_id
      }
    })

    if (res.status === 1) {
      setLoading(false)
      setInsuranceDetails(res.data)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log(234234);
    fetchDetails(id)
  }, [id]);

  return !loading ? (
    <Card className="p-4 mb-4">

      {
        insuranceDetails ?
          (<div className='container-fluid p-0'>
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-between mb-4'>
                <div className='h6'>Company Details</div>
                <div><span className='quotation-status'>Quotation Status :</span> {Badges.INSURANCE_STATUS[insuranceDetails.status]}</div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Company Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.display_name ? insuranceDetails.customer_user_details.display_name : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Email Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.email ? insuranceDetails.customer_user_details.email : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Phone Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.phone ? insuranceDetails.customer_user_details.phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Alternate Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.alternate_phone ? insuranceDetails.customer_user_details.alternate_phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.address ? insuranceDetails.customer_user_details.address : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Agent Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.agent_details ? <span onClick={() => {
                      // navigate(Routes.AgentView.path.replace(':id', insuranceDetails.agent_details.agent_id))
                    }} className='nav-link cursor-pointer'>{insuranceDetails.agent_details.display_name}</span> : '-'}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='border-top-line mb-4'></div>
            <div className='row'>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Owner Details</div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>First Name</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.first_name ? insuranceDetails.company_owner_information.first_name : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Last Name</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.last_name ? insuranceDetails.company_owner_information.last_name : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Nature of Business</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.nature_of_business ? insuranceDetails.company_owner_information.nature_of_business : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Number of Owners</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.num_owners ? insuranceDetails.company_owner_information.num_owners : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Gross Annual Sales</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.gross_annual_sales ? insuranceDetails.company_owner_information.gross_annual_sales : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Number of Employees</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.num_employees ? insuranceDetails.company_owner_information.num_employees : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Annual Employee Payroll</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.annual_employee_payroll ? insuranceDetails.company_owner_information.annual_employee_payroll : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Subcontractors Used</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.subcontractors_used ? insuranceDetails.company_owner_information.subcontractors_used : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Annual Cost of Subcontractors</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.annual_cost_subcontractors ? insuranceDetails.company_owner_information.annual_cost_subcontractors : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Square Footage of Location</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.square_footage_location ? insuranceDetails.company_owner_information.square_footage_location : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Coverage Options</div>
                </div>
                {insuranceDetails.commercial_auto_coverage_options.length ?
                  <Carousel interval={null} indicators={insuranceDetails.commercial_auto_coverage_options.length === 1 ? false : true} controls={false} variant="dark">

                    {insuranceDetails.commercial_auto_coverage_options.map((auto_option, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Injury Protection</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.injury_protection ? auto_option.injury_protection : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Comprehensive Deductible</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.comprehensive_deductible ? auto_option.comprehensive_deductible : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Collision Deductible</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.collision_deductible ? auto_option.collision_deductible : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Rental</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.rental ? auto_option.rental : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Towing</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.towing ? auto_option.towing : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Number of Additional Insureds Needed</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.num_additional_insureds ? auto_option.num_additional_insureds : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>How did you hear about us?</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details.hear_about_us_options_label ? insuranceDetails.customer_details.hear_about_us_options_label : '-') : '-'}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}
                  </Carousel> :
                  <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Injury Protection</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Comprehensive Deductible</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Collision Deductible</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Rental</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Towing</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Number of Additional Insureds Needed</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>How did you hear about us?</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>

                  </>
                }
              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Vehicle Information</div>
                </div>

                {insuranceDetails.vehicle_information.length ?
                  <Carousel interval={null} indicators={insuranceDetails.vehicle_information.length === 1 ? false : true} controls={false} variant="dark">

                    {insuranceDetails.vehicle_information.map((vehicle_info, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Year</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.year ? vehicle_info.year : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Make</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.make_name ? vehicle_info.make_name : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Model</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.modal_name ? vehicle_info.modal_name : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>VIN Number</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.vin_number ? vehicle_info.vin_number : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Annual Mileage</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.annual_mileage ? vehicle_info.annual_mileage : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Drive to work or school</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.is_drive_to_work_or_school ? Badges.BOOLEAN[parseInt(vehicle_info.is_drive_to_work_or_school)] : Badges.BOOLEAN[0]}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Number of miles (one way)</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.number_of_miles_one_way ? vehicle_info.number_of_miles_one_way : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Days per week</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.days_per_week ? vehicle_info.days_per_week : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Comprehensive Deductible</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.comprehensive_deductible ? vehicle_info.comprehensive_deductible : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Collision Deductible</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.collision_deductible ? vehicle_info.collision_deductible : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Towing</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.towing ? vehicle_info.towing : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Rental</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.rental ? vehicle_info.rental : "-"}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}
                  </Carousel>
                  : <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Year</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Make</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Model</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>VIN Number</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Annual Mileage</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Drive to work or school</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Number of miles (one way)</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Days per week</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Comprehensive Deductible</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Collision Deductible</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Towing</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Rental</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div></>}
              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Additional Information</div>
                </div>
                {insuranceDetails.commercial_auto_additional_information.length ?
                  <Carousel interval={null} indicators={insuranceDetails.commercial_auto_additional_information.length === 1 ? false : true} controls={false} variant="dark">

                    {insuranceDetails.commercial_auto_additional_information.map((additional_info, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>License State</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.license_state ? additional_info.license_state : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>License Number</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.license_number ? additional_info.license_number : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Do you currently have insurance?</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.have_insurance ? Badges.BOOLEAN[parseInt(additional_info.have_insurance)] : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Current Insurance Provider?</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.current_insurance_provider ? additional_info.current_insurance_provider : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>If No, when did you have last insurance?</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.last_insurance_date ? commonHelper.formatDate(additional_info.last_insurance_date) : '-'}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}
                  </Carousel> :
                  <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>License State</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>License Number</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Do you currently have insurance?</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Current Insurance Provider?</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>If No, when did you have last insurance?</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>)
          : <div className='text-center w-100 h6'> Insurance Details Not Found</div>
      }
    </Card>
  ) : <PageLoader />;
}
