import React, { useEffect, useState, useCallback } from 'react'
import DataTable from "react-data-table-component";
import { commonHelper } from '../../../common/commonHelper';
import ApiTypes from '../../../networking/APItypes';
import APICall from '../../../networking/AxiousServices';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import InputElement from '../../../components/Forms/InputElement';
import { Enums } from '../../../constants/Enums';
import TableLoader from '../../../loaders/TableLoader';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../routes';
import { Badges } from '../../../constants/Badges';
import { useSelector } from 'react-redux';

export default function BusinessOwners() {
  document.title = `${process.env.REACT_APP_NAME} | Business Owner Quotation List`;
  const UserData = useSelector((state) => state.data);
  let logged_user = UserData.logged_user;

  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [perPageRecords, setPerPageRecords] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState({
    isData: false,
    data: [],
  });

  const [dataTableLoading, setDataTableLoading] = useState(true);
  const [txtSearch, setTxtSearch] = useState("");

  const fetchList = useCallback(async (column = "", sortDirection = "") => {
    let data = {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      filter: {
        search: txtSearch
      },
      insurance_slug: Enums.INSURANCE_SLUG.business_owners
    }
    let res = await APICall({
      url: ApiTypes.insuranceList, data: data
    })
    if (res.status === 1) {
      setList({ isData: true, data: res.data.list });
      setTotalRows(res.data.total)
      setDataTableLoading(false);

    }
  }, [txtSearch, pageNo, perPageRecords])

  const handleSort = async (column, sortDirection) => {
    fetchList(column.sortField, sortDirection);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const columns = [
    {
      name: "#",
      selector: (row, i) => (pageNo === 1 ? i + 1 : (pageNo - 1) * perPageRecords + (i + 1)),
      sortable: true,
      width: "60px",
      sortField: "created_at",
    },
    {
      name: "Company Name",
      selector: (row) => <span className='cursor-pointer nav-link' onClick={() => {
        navigate(Routes.CustomerView.path.replace(':id', row.user_id))
      }}>{row.display_name ? row.display_name : ""}</span>,
      sortable: true,
      width: "150px",
      sortField: "display_name",
    },
    {
      name: "Company Email",
      selector: (row) => <span className='cursor-pointer' title={row.email ? row.email : ''}>{row.email ? row.email : ''}</span>,
      sortable: true,
      width: "150px",
      sortField: "email",
    },
    {
      name: "Agent Name",
      omit: logged_user.user_type === Enums.USER_TYPES.agent ? true : false,
      selector: (row) => <span className='cursor-pointer nav-link' onClick={() => {
        // navigate(Routes.AgentView.path.replace(':id', row.agent_id))
      }}>{row.agent_name ? row.agent_name : ''}</span>,
      sortable: true,
      sortField: "agent_name",
    },
    {
      name: "Owner Name",
      selector: (row) =>
        row.company_owner_information ?
          <span title={((row.company_owner_information.first_name ? row.company_owner_information.first_name : "") + (row.company_owner_information.last_name ? ` ${row.company_owner_information.last_name}` : "")).trim()} className='text-muted cursor-pointer'>{row.company_owner_information.first_name + (row.company_owner_information.last_name ? ` ${row.company_owner_information.last_name}` : "")}</span>
          : ""
      ,
      sortable: false,
    },
    {
      name: "Nature Of Business",
      selector: (row) => (row.company_owner_information ? row.company_owner_information.nature_of_business ? row.company_owner_information.nature_of_business : '' : ''),
      sortable: false,
    },
    // {
    //   name: "Total Owners",
    //   selector: (row) => (row.company_owner_information ? row.company_owner_information.num_owners ? row.company_owner_information.num_owners : '' : ''),
    //   sortable: false,
    // },
    {
      name: "Total Employees",
      selector: (row) => (row.company_owner_information ? row.company_owner_information.num_employees ? row.company_owner_information.num_employees : '' : ''),
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => Badges.INSURANCE_STATUS[row.status],
      sortable: true,
      width: "90px",
      sortField: "status",
    },
    {
      name: "Created At",
      selector: (row) => commonHelper.formatDate(row.created_at),
      sortable: true,
      width: "110px",
      sortField: "created_at",
    },
    {
      name: "Action",
      width: "80px",
      sortable: false,
      cell: (row) => <span>
        <FontAwesomeIcon onClick={() => {
          console.log('row.customer_insurance_id', row.customer_insurance_id)
          navigate(Routes.BusinessOwnerView.path.replace(':id', row.customer_insurance_id))
        }} className='cursor-pointer' icon={faEye} />
      </span>
    }
  ];

  useEffect(() => {
    fetchList("", "")
  }, [fetchList])


  return (<>
    <Card className="p-4 mb-4">
      <div className='container-fluid p-0'>
        <div className="row d-flex justify-content-between align-items-center"><div className="col-4"></div>
          <div className="col-2 d-flex justify-content-end"></div>
        </div>
        <div className='col-4'>
          <div className="h6">Business Owner Quotation List</div>
        </div>
        <div className='search-col-top d-flex justify-content-end mb-4'>
          <InputElement
            is_required={false}
            value={txtSearch}
            inputClass={"custom-table-search"}
            name="search"
            onChange={(name, value) => {
              setTxtSearch(value)
              if (value) {
                setResetPaginationToggle(true)
              } else {
                setResetPaginationToggle(false)
              }
            }}
            type="text"
            placeholder="Search"
          />

        </div>
        <div className="z-adjust-for-dropdown">
          <DataTable
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="440px"
            data={list.data}
            progressPending={dataTableLoading}
            progressComponent={<TableLoader columns={columns} />}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
          />
        </div>
      </div>
    </Card></>)
}
